import React, { useEffect } from 'react'
import { Button, Space, Table } from 'antd';
import { EditOutlined,EyeOutlined } from '@ant-design/icons';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Client',
    dataIndex: 'client_name',
    key: 'client_name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<EyeOutlined />} type="primary" href={`/admin/commodities/${record.id}`}>View Commodity</Button>
        <Button icon={<EditOutlined />} type="primary" href={`/admin/commodities/${record.id}/edit`}>Edit Commodity</Button>
      </Space>
    ),
  },
  
];

const CommoditiesTable = (props) => {
  console.log(props.data)
  useEffect(() => {
  },[])
  return (<Table loading={props.load}  columns={columns} dataSource={props.data} />)
};
export default CommoditiesTable;