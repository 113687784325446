import React, { useEffect } from 'react'
import { Button, Space, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DeleteClient from './deleteClient';
const columns = [
  {
    title: 'Parcel ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Kit ID',
    dataIndex: 'kit_key',
    key: 'kit_key',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Parcel Number',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Generic Parcel',
    dataIndex: 'generic',
    key: 'generic',
    render: (text) => <>{text ? text.toString(): "false"}</>
  },
  {
    title: 'Parcel Type',
    dataIndex: 'parcel_type',
    key: 'parcel_type',
  },
  {
    title: 'Used by',
    dataIndex: 'use',
    key: 'use',
  },
  {
    title: 'Used',
    dataIndex: 'used',
    key: 'used',
    render: (text) => <>{text ? text.toString(): "false"}</>
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (text) => <>{text ? text.toString(): "false"}</>
  },
  {
    title: 'Client ID',
    dataIndex: 'client_id',
    key: 'client_id',
  }
  
];

const KitTable = (props) => {
    console.log(props)
  useEffect(() => {
  },[])
  return (<Table  columns={columns} dataSource={props.kit} />)
};
export default KitTable;