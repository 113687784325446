import React, { useEffect } from 'react'
import { Button, Space, Table } from 'antd';
import { EditOutlined,EyeOutlined } from '@ant-design/icons';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<EyeOutlined />} type="primary" href={`/admin/destinations/${record.id}`}>View Destination</Button>
        <Button icon={<EditOutlined />} type="primary" href={`/admin/destinations/${record.id}/edit`}>Edit Destination</Button>
      </Space>
    ),
  },
  
];

const DestinationsTable = (props) => {
  useEffect(() => {
  },[])
  return (<Table  columns={columns} dataSource={props.data} />)
};
export default DestinationsTable;