import { Button, message, Popconfirm } from 'antd';
import React from 'react'

const DeleteClient = (props) => {
    const confirm = (e) => {
        var token = document.getElementsByName("csrf-token")[0].content;
        fetch(`/admin/zones/${props.zone.id}.json`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": token
            },
            credentials: "same-origin",
        })
        .then(res => {
          window.location.assign('/admin/zones')
        })
      message.success('Click on Yes');
    };
return (
  <Popconfirm
    title={`Delete Zone ${props.zone.name}`}
    description="Are you sure to delete this Zone?"
    onConfirm={confirm}
    okText="Yes"
    cancelText="No"
  >
    <Button danger>Delete</Button>
  </Popconfirm>
);
}
export default DeleteClient;