import React, { useEffect } from 'react'
import { Button, Space, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DeleteClient from './deleteClient';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'System',
    dataIndex: 'domain',
    key: 'domain',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Button type="primary" icon={<EditOutlined />} href={`/admin/clients/${record.id}/edit`}>Edit {record.name}</Button>
        <DeleteClient client={record} />
      </Space>
    ),
  },
  
];

const ClientsTable = (props) => {
  useEffect(() => {
  },[])
  return (<Table  columns={columns} dataSource={props.data} />)
};
export default ClientsTable;