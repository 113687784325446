import React, { useEffect,useState } from 'react'
import { Space, Table, Tag,Spin,Badge, Tooltip,Button, Dropdown } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { CloudDownloadOutlined,MenuOutlined,EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'
import { useSearchParams,Router } from "react-router-dom";

const ReturnBadgeStatus = (status) => {
  switch(status){
    case 'Fail':
      return "error"
      break;
    case 'Awaiting Pickup':
      return "processing"
      break;
    case 'In Transit':
      return 'success'
    case 'Exception':
      return 'warning'
    default:
      return "default"
  }
}

function ReturnDocDropdown(props){
  if (props.docs){
    const items = [
      {
        key: '1',
        label: (
          <a type='primary' target={"_blank"} rel="noopener noreferrer" href={props.docs.awb_document}>
            AWB Document
          </a>
        ),
        icon: <CloudDownloadOutlined />
      },
      {
        key: '2',
        label: (
          <a type='primary' target={"_blank"} rel="noopener noreferrer" href={props.docs.ci_document}>
            Commercial Invoice Document
          </a>
        ),
        icon: <CloudDownloadOutlined />
      }
    ];
    return (
      <Dropdown
      menu={{
        items,
      }}
    >
      <Button type='primary' onClick={(e) => e.preventDefault()}>
        <Space>
          <MenuOutlined />
        </Space>
      </Button>
    </Dropdown>
    )
  } else {
    return (
      "N/A"
    )
  }
 
}

function returnColor(created_by_owner){
  if (created_by_owner){
    return "orange"
  } else {
    return "#498CA4"
  }
}


const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <Button icon={<EyeOutlined style={{margin:3}} />} type='primary' href={`/shipments/${text}`}>{text}</Button>,
  },
  {
    title: 'Created on',
    dataIndex: 'created_on',
    key: 'created_on',
    render: (text) => {return (<Tooltip title={dayjs(text).format('HH:mm')}>{dayjs(text).format("DD/MM/YYYY")}</Tooltip>)}
  },
  {
    title: 'Parcel Number(s)',
    dataIndex: 'parcel_numbers',
    key: 'parcel_numbers',
    render: (parcels) => {
      var a= []
      parcels.forEach(parcel => {
        a.push( <a href={`/admin/parcels/${parcel.id}`}><Tag color={returnColor(parcel.created_by_owner)} style={{margin:1}}>{parcel.number}</Tag></a>)
      }) 
      return a
    }
  },
  {
    title: 'Vessel/Dealer',
    key: 'owner',
    ellipsis: {
      showTitle: false,
    },
    render: (owner) => {
      if (owner.owner){
        if (typeof owner.owner === 'string'){
          return <div style={{overflow: 'scroll'}}><p>{owner.owner}</p></div>
        } else {
          return (
            <Tooltip placement="topLeft" title={`Vessel: ${owner.owner.name}, IMO: ${owner.owner.imo}`}>
              {owner.owner.name}
            </Tooltip>
          )
        }
        
      } else {
        return (
          <p>Unknown</p>
        )
      }
    },
   },
  {
    title: 'Origin',
    key: 'origin',
    dataIndex: 'origin',
    render: (text) => {if(text === undefined){return null} else {return (<p>{getUnicodeFlagIcon(text.code)} {text.name}</p>)}}
  },
  {
    title: 'Destination',
    key: 'destination',
    dataIndex: 'destination',
    render: (text) => {if(text === undefined){return null} else {return (<Tooltip title={text[1]}>{getUnicodeFlagIcon(text[0].code)} {text[0].name}</Tooltip>)}}
  },
  {
    title: 'AWB Number',
    key: 'awb',
    dataIndex: 'awb',
    render: (text) => {return(<Tooltip title={text.collection_reference}>{text.awb_number}</Tooltip>)}
  },  
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    filters: [
      {
        text: 'Awaiting Pickup',
        value: 'Awaiting Pickup',
      },
      {
        text: 'In Transit',
        value: 'In Transit',
      },
      {
        text: 'Exception',
        value: 'Exception',
      },
    ],
    onFilter: (value, record) => record.status.startsWith(value),
    filterSearch: true, 
    render: (text,record) => {return (<Tooltip title={record.latest_tracking}><Badge status={ReturnBadgeStatus(text)} text={<Tag size='medium' color={ReturnBadgeStatus(text)}>{text}</Tag>} /></Tooltip>)}
  },
  {
    title: 'Client',
    key: 'client',
    dataIndex: 'client',
    filters: [
      {
        text: 'Smit Lamnalco',
        value: 'Smit Lamnalco',
      },
      {
        text: 'Chevron',
        value: 'Chevron',
      },
      {
        text: 'Volvo',
        value: 'Volvo',
      },
      {
        text: 'Veriwater',
        value: 'Veriwater',
      },
      {
        text: 'VeriWater',
        value: 'VeriWater',
      },
      {
        text: 'Verilube',
        value: 'Verilube',
      },
      {
        text: 'VeriLube',
        value: 'VeriLube',
      },
      {
        text: 'Boskalis',
        value: 'Boskalis',
      },
      {
        text: 'Flame Diagnostic',
        value: 'Flame Diagnostic',
      },
    
    ],
    onFilter: (value, record) => record.client.startsWith(value),
    filterSearch: true, 
    render: (text) => {if (text){return text} else {return "N/A"}}
  },
  {
    title: 'Documents',
    key: 'documents',
    dataIndex: 'documents',
    ellipsis:{
      showTitle: false
    },
    render: (text) => <ReturnDocDropdown docs={text} />
  },
];

const AdminShipmentsTable = (props) => {
  useEffect(() => {
  },[])
  if (props.load){
    return (
    <Spin tip="Loading...">
      <Table  columns={columns} />
    </Spin>
    )
  } else {
  return (<Table bordered  onChange={(e) => {props.params.set("page", e.current);history.replaceState(null, null, "?"+ props.params.toString());props.fetchShipments()}}  columns={columns} pagination={true} dataSource={props.shipments} />)
  }
};
export default AdminShipmentsTable;