import React, { useEffect } from 'react'
import { Badge } from 'antd';


const ParcelStatus = (props) => {
    console.log(props)
  if (props.active && !props.used){
    return (<Badge status='processing' text='Awaiting Shipment Registration'/>)
  } else if (props.active && props.used) {
    return (<Badge status='processing' text={props.shipment.status}/>)
  }
};
export default ParcelStatus;