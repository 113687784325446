import React, { useEffect } from 'react'
import { Button, Space, Table,Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DeleteVessel from './deleteVessel';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'IMO',
    dataIndex: 'imo',
    key: 'imo',
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (text) => text ? <Tag color='success'>{text.toString()}</Tag> : <Tag color='error'>{text.toString()}</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Button type="primary" icon={<EditOutlined />} href={`/admin/vessels/${record.id}/edit`}>Edit {record.name}</Button>
      </Space>
    ),
  },
  
];

const VesselsTable = (props) => {
  return (<Table  columns={columns} dataSource={props.data} />)
};
export default VesselsTable;