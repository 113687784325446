import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space,Form,Input } from 'antd';
const CancelCollectionModal = (props) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm()
    const reasonValue = Form.useWatch('reason', form);
    const cancelCollection = () => {
        form.validateFields().then(e => {
        var token = document.getElementsByName("csrf-token")[0].content;
        setConfirmLoading(true);
          props.setCancelCollectionModal(false);
          setConfirmLoading(false);
          fetch(`/shipment_cancel_collection_request`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": token
            },
            credentials: "same-origin",
            body:JSON.stringify({shipment: props.shipment.id,cancellation_reason: reasonValue})

        })
        .then(res => {
            return res.json()
            .then((json) => {
              if (res.ok) {
                return Promise.resolve(json)
              }
              if (res.status === 503){
                return Promise.reject({Status: {Message:'Service Unavailable', Detail: 'Service Currently Unavailable. Please Try Again Later'}})
              }else {
                return Promise.reject(json)
              }
            }).then(response => {
                props.setShipment(response.shipment)
                props.setCancelCollectionModal(false);
                setConfirmLoading(false);
                props.collectionNotification('success', 'Success', `Collection Successfully Cancelled`)        
            })
        })
        }).catch(err => {console.log(err); props.collectionNotification('error', 'Error', "Collection could not be Cancelled")})
      };
  const hideModal = () => {
    props.setCancelCollectionModal(false);
  };
  return (
    <>
     
      <Modal
        title={`Cancel Collection`}
        width={600}
        open={props.open}
        onCancel={hideModal}
        confirmLoading={confirmLoading}
        footer={[
            <Space direction='row'>            
            <Form.Item>
            <Button htmlType="submit" onClick={hideModal}>
                        Close
                    </Button>
            </Form.Item>
            <Form.Item>
                    <Button loading={confirmLoading} htmlType="submit" type='primary' onClick={cancelCollection}>
                        Submit
                    </Button>
                </Form.Item>
                </Space>

            ]}
    
      >
        
         <p>AWB: {props.shipment.awb_number}</p>
         <p>Collection Ref: {props.shipment.pieces.collection_reference} </p>
        <Form
        load
        form={form}
        name="cancelShipmentCollection"
        >
            <Form.Item rules={[ { max: 50, required: true, message: 'A Cancellation reason is required' }, ]} name='reason' label='Reason' required><Input /></Form.Item>
        </Form>
       
      </Modal>
    </>
  );
};

export default CancelCollectionModal;