import React, { useEffect } from 'react'
import { Button, Space, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import DeleteClient from './deleteClient';
const columns = [
  {
    title: 'Parcel ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Kit ID',
    dataIndex: 'kit_key',
    key: 'kit_key',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Parcel Number',
    dataIndex: 'number',
    key: 'number',
  }
  
];

const KitTableCompact = (props) => {
  useEffect(() => {
  },[])
  return (<Table  columns={columns} dataSource={props.kit} />)
};
export default KitTableCompact;