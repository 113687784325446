import React, { useEffect } from 'react'
import { Button, Space, Table } from 'antd';
import { PoweroffOutlined,EyeOutlined } from '@ant-design/icons';
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Parcel Number',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Client',
    dataIndex: 'client_id',
    key: 'client_id',
  },
  {
    title: 'Generic Parcel',
    dataIndex: 'generic',
    key: 'generic',
    render: (text) => {return <>{text ? "true" : "false"}</>}
  },
  {
    title: 'Used by',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: 'Used',
    dataIndex: 'used',
    key: 'used',
    render: (text) => <>{text ? text.toString(): "false"}</>
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (text) => <>{text ? text.toString(): "false"}</>
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        {record.client_id ? <Button icon={<EyeOutlined />} type="primary" href={`/admin/parcels/${record.id}`}>View Parcel Details</Button> : <> <Button icon={<EyeOutlined />} type="primary" href={`/admin/parcels/${record.id}`}>View Parcel Details</Button></>}
        
      </Space>
    ),
  },
  
];

const ParcelsTable = (props) => {
  console.log(props)
  useEffect(() => {
  },[])
  return (<Table  columns={columns} dataSource={props.data} />)
};
export default ParcelsTable;