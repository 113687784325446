import { Button,Modal,Form,Select,Input,Row,Col,DatePicker,TimePicker,Divider,Space} from 'antd';
import React,{useState,useRef, useEffect,Alert} from 'react'
import { PlusOutlined,MinusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs'

const BookCollectionModal = (props) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm()
    const [name, setName] = useState('');
    const [pickupLocations,setPickupLocations] = useState(['Front Door','Reception','Warehouse'])
    const inputRef = useRef(null);
    const [collectionEarly, setCollectionEarly] = useState(dayjs())
    const [collectionLate, setCollectionLate] = useState(dayjs().set('hour', 17).set('minute', 0))

    const onNameChange = (event) => {
        setName(event.target.value);
      };
      const addItem = (e) => {
        e.preventDefault();
        setPickupLocations([...pickupLocations, name || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      };
    const onFinish = (values) => {
        var url = (props.collection ? `/shipment_update_collection?id=${props.shipment.id}` : `/shipment_collection_request?id=${props.shipment.id}`)
        var shipmentPickupDetails = values
        shipmentPickupDetails.pickupDateTime = dayjs(`${dayjs(values.pickupDateTime).format("YYYY-MM-DD")}T${collectionEarly.format("HH:mm")}`).format()
        shipmentPickupDetails.pickupEarlyTime = dayjs(collectionEarly).format("HH:mm")
        shipmentPickupDetails.pickupCloseTime = dayjs(collectionLate).format("HH:mm")
        var token = document.getElementsByName("csrf-token")[0].content;
        setConfirmLoading(true);
         
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": token
            },
            credentials: "same-origin",
            body:JSON.stringify({shipment: props.shipment.id, pickupDetails: shipmentPickupDetails})
        })
          .then((res) => {
            return res.json()
              .then((json) => {
                if (res.ok) {
                  return Promise.resolve(json)
                }
                if (res.status === 503){
                  return Promise.reject({Status: {Message:'Service Unavailable', Detail: 'Service Currently Unavailable. Please Try Again Later'}})
                }else {
                  return Promise.reject(json)
                }
              })
        }).then(response => {
          props.setShipment(response.shipment)
          props.setCollectionModal(false);
          setConfirmLoading(false);
          props.collectionNotification('success', 'Success', `${response.shipment.pieces.collection_reference} Successfully Arranged`)        
        }).catch(err => {
          console.log(err)
          setConfirmLoading(false);
          props.collectionNotification('error', err.Status.Message, err.Status.Detail)        
        })
      };
      const handleCancel = () => {
        props.setCollectionModal(false);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
return (
    <Modal
    title={`Edit Shipment ${props.shipment.id}`}
    open={props.open}
    onOk={() => {onFinish(form.getFieldsValue('collectionEarly','collectionLate'))}}
    confirmLoading={confirmLoading}
    onCancel={handleCancel}
    width={900}
    >
    <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ pickupDateTime: dayjs(),pickupLocation: 'Reception'}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item rules={[ { required: true, message: 'Collection Date is required' }, ]} name='pickupDateTime' label="Collection Date"><DatePicker style={{width:300}} format={"dddd DD MMMM YYYY"} /></Form.Item>
        <Form.Item rules={[ { required: true, message: 'Earliest Collection Time is required' }, ]}  name='collectionEarly' label="Earliest Collection Time" > <TimePicker value={collectionEarly} onChange={setCollectionEarly} format={"HH:mm"}/> </Form.Item>
        <Form.Item rules={[ { required: true, message: 'Latest Collection Time is required' }, ]} name='collectionLate' label="Latest Collection Time" > <TimePicker  value={collectionLate} onChange={setCollectionLate} format={"HH:mm"}/> </Form.Item>
        <Form.Item name='pickupLocation' label='Pickup Location' required>
        <Select
      style={{
        width: 300,
      }}
      placeholder="Select Location..."
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
              maxLength={50}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add Custom Location
            </Button>
          </Space>
        </>
      )}
      options={pickupLocations.map((item) => ({
        label: item,
        value: item,
      }))}
    />
        </Form.Item>
    
    </Form>
    
  </Modal>
);
}
export default BookCollectionModal;