import React, { useEffect } from 'react'
import { Button, List, Space, Table, Typography } from 'antd';
import { PoweroffOutlined,EyeOutlined } from '@ant-design/icons';
import DeleteZone from './deleteZone';
import countryList from 'country-list';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Zone Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    render:(_,record) => (<text>{record.client.name}</text>)
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render:(_,record) => (<text>{record.destination.name}</text>)
  },
  {
    title: 'Countries',
    dataIndex: 'countries',
    key: 'countries',
    render: (_,record) => (
      <List>{record.countries.map(c => {return (<Space><Typography style={{margin:4}}>{getUnicodeFlagIcon(c)} {countryList.getName(c)}</Typography></Space>)})}</List>
    )
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<EyeOutlined />} type="primary" href={`/admin/zones/${record.id}`}>View Zone Details</Button>
        <DeleteZone zone={record} />
      </Space>
    ),
  }
  
];

const ZonesIndexTable = (props) => {
  useEffect(() => {
  },[])
  return (<Table  columns={columns} dataSource={props.zones} />)
};
export default ZonesIndexTable;